import React from 'react'
import Icon from './Icons'
import { revisitConsent } from '../../utils/cookieHelpers'

function SimpleYoutubeNotification(props) {
  const {
    designModifierClassName,
    handleRevisitConsent,
  } = props

  return (
    <div className={`youtubeBlocked-container ${designModifierClassName || ''}`}>
      <div className={`youtubeBlocked-content ${designModifierClassName || ''}`}>
        <div className={`youtubeBlocked-icons ${designModifierClassName || ''}`}>
          <Icon name="video-disabled" />
        </div>
        <div className={`youtubeBlocked-text youtubeBlocked-text-simple ${designModifierClassName || ''}`}>
          <p>
            YouTube cookies disabled
            <br />
            <button type="button" className="youtubeBlocked-button-link" onClick={handleRevisitConsent}>Manage settings</button>
            <br />
            Page reload might be needed
          </p>
        </div>
      </div>
    </div>
  )
}

function IconOnlyYoutubeNotification(props) {
  const {
    designModifierClassName,
    handleRevisitConsent,
  } = props

  const tooltipText = 'Enable YouTube Cookies'

  return (
    <div className={`youtubeBlocked-container ${designModifierClassName || ''}`}>
      <div className={`youtubeBlocked-content ${designModifierClassName || ''}`}>
        <div className={`youtubeBlocked-icons fullSizeIcon ${designModifierClassName || ''}`}>
          <div onClick={handleRevisitConsent} data-tooltip={tooltipText} className="tooltip tooltip--above">
            <Icon name="video-disabled" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default function YoutubeBlocked(props) {
  const {
    designModifierClassName,
    notificationType,
    slideScale,
  } = props

  const handleRevisitConsent = (event) => {
    event.preventDefault()
    revisitConsent()
  }

  let notificationTypeToUse = notificationType

  if (slideScale && slideScale < 0.5) {
    notificationTypeToUse = 'iconOnly'
  }

  switch (notificationTypeToUse) {
    case ('iconOnly'):
      return (
        <IconOnlyYoutubeNotification
          designModifierClassName={designModifierClassName}
          handleRevisitConsent={handleRevisitConsent}
        />
      )
    default:
      return (
        <SimpleYoutubeNotification
          designModifierClassName={designModifierClassName}
          handleRevisitConsent={handleRevisitConsent}
        />
      )
  }
}
